import { Button } from "@nextui-org/react";
import React, { useState, useEffect } from "react";
import MintKarmaLogo from "../../../component/Icons/MintKarma/MintKarmaLogo.js";
import SuccessConfirmationIcon from "../../../component/Icons/MintKarma/SuccessConfirmationIcon";
import * as VerifyEmailRequest from "http_request/VerifyEmailRequest.js";
import * as UrlUtil from "util/UrlUtil.js";
import * as ConfigUtil from "util/ConfigUtil.js";

export default function VerifyEmailConfirmation() {
  //const [loading, setLoading] = useState(true);
  const [isSuccessResponse, setIsSuccessResponse] = useState(false);
  const [responseMsg, setResponseMsg] = useState(null);
  
  useEffect(() => {
    let locObj = UrlUtil.getLocationUrlData();
    let hasVal = locObj["hash"];
    VerifyEmailRequest.verifyEmail(hasVal,
      (errorInfo) => {
        if (errorInfo) {
          //setLoading(false);
          setIsSuccessResponse(false);
          if (errorInfo["errorMsg"]) {
            setResponseMsg(errorInfo["errorMsg"]);
          } else {
            let fallbackMsg = "Something went wrong.";
            setResponseMsg(fallbackMsg);
          }
          //ToastUtil.showToastReqError(errorInfo)
        } else {
          //setLoading(false);
          setIsSuccessResponse(true);
          setResponseMsg("Your email has been verified successfully");
        }
    });
  }, []);
  
  return (
    <div
      className="h-screen flex flex-col items-center justify-center overflow-scroll"
      style={{ backgroundColor: "#111312", fontFamily: "Fira Sans, sans-serif"}}>
      <div className="mb-4">
        <MintKarmaLogo />
      </div>
      <div
        className="lg:w-1/3 md:w-1/2 w-96 flex items-center justify-center p-4 rounded-lg"
        style={{ backgroundColor: "#1C1C1C",
        display: (responseMsg != null && isSuccessResponse) ? "block": "none"
        }}>
        <div className="rounded-md p-2 w-full">
          <div className="flex justify-center">
            <div>
              <SuccessConfirmationIcon />
            </div>
          </div>
          <div className="text-center p-4 text-white">
            <div className="text-[#F5F5F5] text-2xl" style={{ fontFamily: 'FiraSans-ExtraBold'}}>Email Verified</div>
            <div className="text-[#BABABA] text-sm mt-4" style={{ fontFamily: 'Montserrat-Regular' }}>
              {responseMsg} 
            </div>
          </div>
          <div className="px-3">
            <a href="https://invite.mintkarma.io/open-app-from-web" style={{ textDecoration: 'none' }}>
              <Button
                className="w-full mt-2 mb-4"
                style={{ backgroundColor: "#B1EDC4", fontFamily: 'FiraSans-Bold', borderRadius: '50px' }}
                onClick={() => {
                  let linkUrl = ConfigUtil.getBranchAppLink();
                  UrlUtil.openUrl2(linkUrl);
 
                }}
                >
              Open MintKarma
            </Button>
            </a>
          </div>
        </div>
      </div>
      {ErrorView(responseMsg, isSuccessResponse)}
    </div>
  );
}

function ErrorView(responseMsg, isSuccessResponse) {
  return <div
          className="lg:w-1/3 md:w-1/2 w-96 flex items-center justify-center p-4 rounded-lg"
          style={{ backgroundColor: "#1C1C1C",
          display: (responseMsg != null && isSuccessResponse === false) ? "block": "none"
  }}>
  <div className="rounded-md p-2 w-full">
    <div className="text-center p-4 text-white">
      <div className="text-[#EF5350] text-2xl" style={{ fontFamily: 'FiraSans-ExtraBold'}}>Verification Failed!</div>
      <div className="text-[#BABABA] text-sm mt-4" style={{ fontFamily: 'Montserrat-Regular' }}>
        {responseMsg} 
      </div>
    </div>
  </div>
</div>
}
